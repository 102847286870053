<template>
    <div>
        <el-scrollbar class="set-container">
            <div class="big-title">概况</div>
            <div class="list-box">
                <div class="item">
                    <div class="left">
                        <div class="text">商品总数</div>
                        <div class="num">{{info.goods_num}}</div>
                    </div>
                    <i class="iconfont">&#xe601;</i>
                </div>
                <div class="item">
                    <div class="left">
                        <div class="text">收件总数</div>
                        <div class="num">{{info.receive_num}}</div>
                    </div>
                    <i class="iconfont">&#xe602;</i>
                </div>
                <div class="item">
                    <div class="left">
                        <div class="text">寄件总数</div>
                        <div class="num">{{info.mail_num}}</div>
                    </div>
                    <i class="iconfont">&#xe603;</i>
                </div>
                <div class="item">
                    <div class="left">
                        <div class="text">用户总数</div>
                        <div class="num">{{info.account_num}}</div>
                    </div>
                    <i class="iconfont">&#xe600;</i>
                </div>
            </div>
            <div class="scroll-box">
                <div class="scroll-item">
                    <div class="scroll-title">寄件记录</div>
                    <template v-if="info.mail_data.list.length > 0">
                        <vue-seamless-scroll :data="info.mail_data.list" :class-option="sendOption" class="scroll-content">
                            <div class="send-item" v-for="(item, index) in info.mail_data.list" :key="`mail_data_${index}`">
                                <div class="left">
                                    <div class="text">{{item.send_city}}</div>
                                    <div class="name">{{item.send_username}}</div>
                                </div>
                                <div class="center">
                                    <div class="text">运单号：{{item.mail_no}}</div>
                                    <img :src="require('../../assets/images/to-right.png')" alt="">
                                </div>
                                <div class="left right">
                                    <div class="text">{{item.accept_city}}</div>
                                    <div class="name">{{item.accept_username}}</div>
                                </div>
                            </div>
                        </vue-seamless-scroll>
                    </template>
                    <div class="no-data" v-else>暂无数据</div>
                </div>

                <div class="scroll-item">
                    <div class="scroll-title">取件记录</div>
                    <template v-if="info.receive_data.list.length > 0">
                        <vue-seamless-scroll :data="info.receive_data.list" :class-option="receiveOption" class="scroll-content">
                            <div class="receive-item" v-for="(item, index) in info.receive_data.list" :key="`receive_data_${index}`">
                                <div class="left">
                                    {{item.accept_user}}
                                    <div class="phone">{{item.mobile}}</div>
                                    {{item.status}}
                                </div>
                                <div class="right">{{item.create_time}}</div>
                            </div>
                        </vue-seamless-scroll>
                    </template>
                    <div class="no-data" v-else>暂无数据</div>
                </div>
            </div>
            <div class="scroll-box" style="margin-bottom: 20px">
                <div class="scroll-item">
                    <div class="scroll-title">商品概况</div>
                    <template v-if="info.goods_data.list.length > 0">
                        <vue-seamless-scroll :data="info.goods_data.list" :class-option="sendOption" class="scroll-content">
                            <div class="good-item" v-for="(item, index) in info.goods_data.list" :key="`goods_data_${index}`">
                                <div class="left">
                                    <img :src="item.img_url" alt="">
                                </div>
                                <div class="center">
                                    <div class="title text-overflow">
                                        {{item.goods_name}}
                                    </div>
                                    <div class="price">
                                        价格：<span class="red">&yen;{{item.price}}</span> <span style="text-decoration: line-through">&yen;{{item.market_price}}</span>
                                    </div>
                                </div>
                            </div>
                        </vue-seamless-scroll>
                    </template>
                    <div class="no-data" v-else>暂无数据</div>
                </div>

                <div class="scroll-item">
                    <div class="scroll-title">动态概况</div>
                    <template v-if="info.dynamic_data.list.length > 0">
                        <vue-seamless-scroll :data="info.dynamic_data.list" :class-option="receiveOption" class="scroll-content">
                            <div class="receive-item" v-for="(item, index) in info.dynamic_data.list" :key="`dynamic_data_${index}`">
                                <div class="left">
                                   <div class="text-overflow">{{item.title}}</div>
                                </div>
                                <div class="right">{{item.created_at}}</div>
                            </div>
                        </vue-seamless-scroll>
                    </template>
                    <div class="no-data" v-else>暂无数据</div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import { dataManageCourierIndex } from '@/config/apis.js'
    import vueSeamlessScroll from "vue-seamless-scroll";

    export default {
        name: "Index",
        components: {
            vueSeamlessScroll,
        },
        data() {
            return {
                info: {
                    mail_data: {
                        list: [],
                        total: 0,
                    },
                    receive_data: {
                        list: [],
                        total: 0,
                    },
                    goods_data: {
                        list: [],
                        total: 0,
                    },
                    dynamic_data: {
                        list: [],
                        total: 0,
                    },
                },
                sendOption: {
                    singleHeight: 98,
                    hoverStop: true,
                    waitTime: 1500
                },
                receiveOption: {
                    singleHeight: 79,
                    hoverStop: true,
                    waitTime: 1500,
                },
            }
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            getInfo() {
                let params = {
                    mail_paging: 1,
                    mail_page: 1,
                    mail_pageSize: 10,

                    receive_paging: 1,
                    receive_page: 1,
                    receive_pageSize: 10,

                    goods_paging: 1,
                    goods_page: 1,
                    goods_pageSize: 10,

                    dynamic_paging: 1,
                    dynamic_page: 1,
                    dynamic_pageSize: 10,
                }
                dataManageCourierIndex(params).then(res => {
                    if(res.code===200){
                        this.info = res.data
                    }
                    
                }).catch(err => {})
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .set-container {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .big-title {
        margin: 20px 20px 0;
        font-size: 20px;
        color: #000;
        font-weight: bold;
        line-height: 1;
    }
    .list-box {
        display: flex;
        justify-content: space-between;
        margin: 20px 20px 0;
        .item {
            width: 1%;
            flex: 1;
            border: 1px solid #F2F2F2;
            border-radius: 4px;
            padding: 20px;
            display: flex;
            align-items: center;
            .left {
                width: 1%;
                flex: 1;
                .text {
                    color: #999;
                }
                .num {
                    color: #409eff;
                    font-size: 24px;
                    font-weight: bold;
                    margin-top: 6px;
                }
            }
            .iconfont {
                font-size: 124px;
                transform: rotate(30deg);
                color: #409eff;
                opacity: .08;
            }
        }
        .item + .item {
            margin-left: 20px;
        }
    }
    .no-data {
        height: 394px;
        margin-top: 10px;
        background: #f1f7ff;
        border: 1px solid #F2F2F2;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;
        font-size: 18px;
    }
    .scroll-box {
        display: flex;
        margin: 20px 20px 0;
        .scroll-item {
            width: 1%;
            flex: 1;
            .scroll-title {
                font-size: 16px;
                color: #000;
            }
            .scroll-content {
                height: 394px;
                overflow: hidden;
                margin-top: 10px;
                background: #f1f7ff;
                padding: 0 20px 10px;
                border: 1px solid #F2F2F2;
            }
        }
        .scroll-item + .scroll-item {
            margin-left: 20px;
        }
        .send-item {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            padding: 20px;
            border: 1px solid #F2F2F2;
            border-radius: 4px;
            margin-top: 10px;
            .left {
                margin-right: 40px;
                text-align: center;
                .text {
                    font-size: 18px;
                }
                .name {
                    color: #666;
                }
            }
            .right {
                margin-left: 40px;
            }
            .center {
                position: relative;
                .text {
                    position: absolute;
                    left: 0;
                    top: -10px;
                    font-size: 12px;
                    color: #666;
                }
            }
        }
        .receive-item {
            display: flex;
            align-items: center;
            background: #fff;
            padding: 0 24px;
            height: 69px;
            box-sizing: border-box;
            border: 1px solid #F2F2F2;
            border-radius: 4px;
            margin-top: 10px;
            .left {
                width: 1%;
                flex: 1;
                display: flex;
                .phone {
                    margin: 0 30px;
                }
            }
            .right {
                margin-left: 20px;
            }
        }
        .good-item {
            display: flex;
            align-items: center;
            background: #fff;
            padding: 13px 20px;
            border: 1px solid #F2F2F2;
            border-radius: 4px;
            margin-top: 10px;
            .left {
                width: 60px;
                height: 60px;
                overflow: hidden;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .center {
                width: 1%;
                flex: 1;
                margin-left: 20px;
                .price {
                    color: #666;
                    margin-top: 10px;
                }
            }
        }
    }
    .red {
        color: #FF2400;
        margin-right: 10px;
    }
</style>